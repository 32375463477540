@use 'sass:map';
// @use 'bootstrap';  // For Mobile breakpoint

@mixin colour($config-or-theme) {
  $eeg-theme: map.get($config-or-theme, eeg);

  $eeg-report-grey: map.get($eeg-theme, report-grey);
  $eeg-report-green: map.get($eeg-theme, report-green);

  //----------------------------------------
  // Desktop
  .eeg-milestone {
    color: $eeg-report-grey;
    font-size: 22pt;
    font-weight: bold;
    &.pie-chart-label {
      font-size: 10pt !important;
    }
  }

  .eeg-theme {
    color: $eeg-report-grey;
    font-size: 14pt;
    font-weight: bold;
  }

  .eeg-action {
    color: $eeg-report-grey;
    font-size: 10pt;
    font-weight: bold;
  }
  h5.eeg-action {
    color: $eeg-report-green !important;
  }
  i.outdent {
    color: $eeg-report-green !important;
    margin-left: -15px;
  }

  // //----------------------------------------
  // // Mobile
  // @include bootstrap.media-breakpoint-down(sm) {
  // }

  //----------------------------------------
  // Print
  @media print {
    .app-container.vh-100 {
      height: unset !important; // Stop PDF and print preview only showing first page
    }
    body {
      font-family: arial !important;
    }
    .app-container.overflow-hidden, .app-sidenav-container {
      overflow: unset !important;
      background-color: white !important;
    }
  }
}

// @mixin typography($config-or-theme) {
//   // Get the typography config from the theme.
//   $typography-config: mat.get-typography-config($config-or-theme);

//   .my-carousel {
//     font-family: mat.font-family($typography-config);
//   }
// }

@mixin theme($config-or-theme) {
  @include colour($config-or-theme);
  // @include typography($config-or-theme);
}
