@use 'sass:map';
@use '@angular/material' as mat;
// @use 'bootstrap';

@mixin colour($config-or-theme) {
    // Get the primary color palette from the color-config.
    $colour-config: mat.get-color-config($config-or-theme);
    $primary-palette: map.get($colour-config, primary);

    $eeg-theme: map.get($config-or-theme, eeg);

    $eeg-report-grey: map.get($eeg-theme, report-grey);
    $eeg-report-green: map.get($eeg-theme, report-green);

    //----------------------------------------
    // Desktop
    .mat-checkbox {
        vertical-align: sub;
    }

    // Checklist
    .cdk-drag {
        &-animating {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
        &-handle {
            cursor: move;
        }
        &-preview {
            box-sizing: border-box;
            border-radius: 4px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }
    }

    .checklist {
        &-custom-placeholder {
            background: #ccc;
            border: dotted 3px #999;
            min-height: 60px;
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
        &-item {
            padding: 10px 0 10px 0;
            margin: 5px 0 5px 0;

            &.cdk-drop-list-dragging .checklist-item-box:not(.cdk-drag-placeholder) {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
            &:nth-child(even) {
                background-color: mat.get-color-from-palette($primary-palette, 200);
            }
            &:nth-child(odd) {
                background-color: mat.get-color-from-palette($primary-palette, 100);
            }
        }
    }

    .trash-can {
        font-size: 45px;
    }

    // Other
    .eeg {
        &-milestone {
            color: $eeg-report-grey;
            font-size: 22pt;
            font-weight: bold;
            &.pie-chart-label {
                font-size: 10pt !important;
            }
        }

        &-theme {
            color: $eeg-report-grey;
            font-size: 14pt;
            font-weight: bold;
        }

        &-action {
            color: $eeg-report-grey;
            font-size: 10pt;
            font-weight: bold;
        }
    }
    h5.eeg-action {
        color: $eeg-report-green !important;
    }
    i.outdent {
        color: $eeg-report-green !important;
        margin-left: -15px;
    }

    // //----------------------------------------
    // // Mobile
    // @include bootstrap.media-breakpoint-down(sm) {
    // }

    //----------------------------------------
    // Print
    // @media print {
    // }
}

@mixin typography($config-or-theme) {
  // Get the typography config from the theme.
  // $typography-config: mat.get-typography-config($config-or-theme);

  // .my-carousel {
  //   font-family: mat.font-family($typography-config);
  // }
}

@mixin theme($config-or-theme) {
  @include colour($config-or-theme);
  @include typography($config-or-theme);
}
