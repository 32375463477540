/* Global styles */
//========================================
@use "sass:meta";
@use '@angular/material' as mat;
@use './theme.scss' as eeg;

// Sizing
// html {
//   font-size: 62.5%; // Makes root font-size=10px to make calculations easier (i.e. 62.5% of 16px = 10px)
// }

//========================================
// App settings
// .app-toolbar {
//   // background: url("/assets/img/uq-header-banner.jpg") no-repeat !important;
//   -webkit-background-size: cover !important;
//   -moz-background-size: cover !important;
//   -o-background-size: cover !important;
//   background-size: cover !important;
// }
html, body {
  height: 100%;
}
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.header-app-name {
  font-family: merriweather !important;
}

.app-toolbar {
  background: url("/assets/img/eeg-header-banner.jpg") no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

// Checklist
.checklist-step-text {
  .header {
    font-weight: bolder !important;
    font-size: 4rem !important;
  }
  .description {
    font-size: smaller !important;
  }
}

.checklist-section-text {
  font-size: 2.4rem !important; // 24px;
}
.checklist-item {
  padding-top: 1rem;  // 10px;
  padding-bottom: 1rem;  // 10px;
  margin-top: 0.5rem;  // 5px;
  margin-bottom: 0.5rem;  // 5px;
  &.item-complete {
    // background-color: #89bf40;
    background-color: mat.get-color-from-palette(eeg.$app-primary, 100);
  }
  .item-text .ql-editor {
    font-size: 2rem !important;  // 20px;
  }
}

// Expanded section colour
.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover, .mat-expansion-panel-header.mat-expanded {
  background-color: mat.get-color-from-palette(eeg.$app-primary, 400) !important;
}

// Tool background
// .mat-stepper-horizontal, .mat-stepper-vertical {
//   background-color: mat.get-color-from-palette(eeg.$app-primary, 200);
// }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: eeg.$eeg-checklist-checked-colour;
}

.mat-step-icon {
  &.mat-step-icon-state-number, &.mat-step-icon-state-edit {
    width: 50px;
  }
}
.mat-step-header .mat-step-icon {
  background-color: inherit;
}

.mat-step-icon-content > highcharts-chart {
  display: inline-block;
  margin-top: -20px;
  margin-left: -15px;
}

.mat-drawer-container {
  background-color: mat.get-color-from-palette(eeg.$app-primary, 200);
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  width: calc(50% - 50px) !important;
}

.mat-step-icon-content, .mat-step-icon .mat-icon {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  transform: none !important;
}

.mat-card>.mat-card-actions:last-child {
  margin-bottom: unset !important;
}
.mat-card-actions {
  margin: unset !important;
}

// Transparent background for pie charts
.highcharts-background {
  display: none;
}
